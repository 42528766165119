<script setup lang="ts">
const { isSuperadmin, userData } = useUserState()
</script>

<template>
  <v-list>
    <v-divider />
    <v-list-item title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Demos" prepend-icon="$mdiDashboard" to="/demostraciones" />
    <v-divider v-if="isSuperadmin || (!isSuperadmin && userData.refCompany?.bucket)" />
    <v-list-item v-if="isSuperadmin || (!isSuperadmin && userData.refCompany?.bucket)" title="Recomendaciones" prepend-icon="$mdiLightbulbOnOutline" to="/insights" />
    <v-divider />
<!--    <v-list-item title="Características" prepend-icon="$mdiProgressCheck" to="/pricing" />
    <v-divider />-->
    <v-divider />
    <template>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="$mdiAIoutline" to="/adify/budget" title="FSBD Adify" />
        </template>
        <v-divider />
        <v-list-item title="Activables" prepend-icon="$mdiCheckActivable" to="/adify/budget" />
        <v-divider />
        <v-list-item title="Procesadas" prepend-icon="$mdiCheckProcessed" to="/adify/processed" />
        <v-divider />
        <v-list-item title="Vencidas" prepend-icon="$mdiCheckExpired" to="/adify/expired" />
      </v-list-group>
      <v-divider />
    </template>
    <v-list-item active title="Contratar" variant="outlined" color="primary" rounded prepend-icon="$mdiFileDocumentEdit" to="/contact" />
    <v-divider />
    <v-list-item v-if="isSuperadmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin" />
  </v-list>
</template>
